import React from 'react';
import { number, string, shape, func, arrayOf, node } from 'prop-types';
import { Pill } from '@andes/badge';
import classnames from 'classnames';
import ReviewSummary from '../review-summary/review-summary.mobile';
import Title from '../title';
import StyledLabel from '../styled-label';
import SellerValidated from '../seller-validated';
import applyclasses from '../../lib/apply-classes';
import Compats from '../compats-widget/highlight';
import CompatsTitle from '../compats-widget/title';
import componentEnhance from '../../lib/component-enhance';

const namespace = 'ui-pdp-header';

const Header = ({
  className,
  subtitle,
  reviews,
  title,
  tag,
  official_store,
  bottom_subtitle,
  link_label,
  onScrollComponent,
  highlights,
  title_compats,
  children,
  label,
  action,
  runCatchErrorBoundary,
}) => {
  try {
    return (
      <div className={classnames(namespace, 'pt-16 pl-16 pr-16', className)}>
        <div className={`${namespace}__container`}>
          <div className={`${namespace}__product-state`}>
            {tag && (
              <Pill
                className={applyclasses({ ...tag, className: `${namespace}__tag` })}
                color="gray"
                size="large"
                hierarchy="quiet"
                roundedCorners={['top-right', 'top-left', 'bottom-right', 'bottom-left']}
              >
                {tag.text}
              </Pill>
            )}
            {subtitle && (
              <div className={classnames(`${namespace}__subtitle`)}>
                <span className="ui-pdp-subtitle">{subtitle}</span>
              </div>
            )}
            {label && (
              <div className={`${namespace}__subtitle`}>
                <span className="ui-pdp-subtitle">{componentEnhance.jsx(label.text, label.values)}</span>
              </div>
            )}
          </div>
          <div className={`${namespace}__product-classification`}>
            {reviews && (
              <div className={`${namespace}__info`}>
                <ReviewSummary {...reviews} />
              </div>
            )}
          </div>
        </div>
        {children}
        {title_compats ? <CompatsTitle title={title} compats={title_compats} /> : <Title title={title} />}
        {highlights && highlights.map(key => <Compats {...key} key={key.id} />)}
        {bottom_subtitle && <StyledLabel className={`${namespace}__bottom-subtitle`} {...bottom_subtitle} />}
        {action && (
          <div className={namespace}>
            <SellerValidated
              title={action.label}
              target={action.target}
              onScrollComponent={action.target ? onScrollComponent : null}
            />
          </div>
        )}
        {official_store && (
          <div className={`${namespace}__store`}>
            {componentEnhance.jsx(official_store.text, official_store.values)}
          </div>
        )}
        {link_label && (
          <SellerValidated
            title={link_label.label}
            icon={link_label.icon}
            target={link_label.target}
            track={link_label.track}
            onScrollComponent={link_label.target ? onScrollComponent : null}
          />
        )}
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Header.propTypes = {
  className: string,
  subtitle: string,
  reviews: shape({
    amount: number,
    rating: number,
    action: shape({
      type: string,
      target: string,
    }),
    stars: string.isRequired,
  }),
  title: string.isRequired,
  tag: shape({
    text: string,
    color: string,
    bgcolor: string,
  }),
  official_store: shape({
    text: string,
    values: shape({
      action: shape({
        type: string,
        target: string,
        label: shape({
          text: string,
        }),
      }),
    }),
  }),
  action: shape({
    target: string,
    label: string,
  }),
  label: shape({
    text: string.isRequired,
    color: string,
    font_family: string,
    font_size: string,
    values: shape({
      action: shape({
        type: string,
        target: string,
        label: shape({
          text: string,
        }),
      }),
    }),
  }).isRequired,
  link_label: shape({
    label: shape({
      text: string.isRequired,
      color: string,
      font_family: string,
      font_size: string,
    }).isRequired,
    icon: shape({
      id: string,
      size: string,
    }).isRequired,
  }),
  bottom_subtitle: shape({}),
  onScrollComponent: func,
  highlights: arrayOf(
    shape({
      description_action: shape({
        action_type: string,
        label: shape({
          bg_color: string,
          color: string,
          font_size: string,
          text: string,
          type: string,
        }),
        target: string,
        timeout: number,
        type: string,
      }),
      icon_dto: shape({
        bg_color: string,
        color: string,
        id: string,
      }),
      id: string,
      state: string,
      tag_action: shape({
        action_type: string,
        label: shape({
          bg_color: string,
          color: string,
          font_size: string,
          text: string,
          type: string,
        }),
        target: string,
        timeout: number,
        type: string,
      }),
      type: string,
    }),
  ),
  title_compats: shape({}),
  children: node.isRequired,
  runCatchErrorBoundary: func,
};

Header.defaultProps = {
  className: '',
  subtitle: null,
  reviews: null,
  tag: null,
  official_store: null,
  bottom_subtitle: null,
  link_label: null,
  onScrollComponent: null,
  highlights: null,
  title_compats: null,
  action: null,
  label: null,
  runCatchErrorBoundary: () => {},
};

export default Header;
