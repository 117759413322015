import React from 'react';
import { shape, string, number, PropTypes } from 'prop-types';
import { trackEvent } from '../../lib/tracking';
import { getStarsToDraw } from './utils/stars-draw';

const namespace = 'ui-pdp-review';
const ReviewSummary = ({
  rating,
  formatted_rating_average,
  action,
  stars,
  rating_stars,
  subtitle,
  formatted_total,
  aria_label,
  force_auto_scroll,
}) => {
  const ratings = getStarsToDraw(rating_stars, stars);
  const scrollToReviews = () => {
    const targetElement = document.getElementById('reviews_capability_v3');
    if (targetElement) {
      const targetOffsetTop = targetElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo(0, targetOffsetTop);
    }
  };
  return (
    <a
      data-testid="reviews-summary-link"
      href={action.target}
      className={`${namespace}__label`}
      onClick={e => {
        if (force_auto_scroll) {
          e.preventDefault();
          scrollToReviews();
        }
        trackEvent(action.track);
      }}
    >
      <span aria-hidden="true" className={`${namespace}__rating`}>
        {/* TODO: lógica en front para hacer safe el deploy, quitar luego de aplicar esta lógica en back */}
        {formatted_rating_average?.text ?? rating.toFixed(1)}
      </span>
      <span aria-hidden="true" className={`${namespace}__ratings`}>
        {ratings}
      </span>
      <span className="andes-visually-hidden">{aria_label}</span>
      <span aria-hidden="true" className={`${namespace}__amount`}>
        {formatted_total?.text ?? subtitle}
      </span>
    </a>
  );
};
ReviewSummary.propTypes = {
  rating: number.isRequired,
  formatted_rating_average: shape({
    text: string,
  }),
  action: shape({
    target: string.isRequired,
    track: shape({}),
  }),
  stars: string.isRequired,
  rating_stars: shape({
    value: number,
  }),
  subtitle: string.isRequired,
  formatted_total: shape({
    text: string,
  }),
  aria_label: string.isRequired,
  force_auto_scroll: PropTypes.bool,
};
ReviewSummary.defaultProps = {
  action: null,
  formatted_rating_average: null,
  rating_stars: null,
  formatted_total: null,
  force_auto_scroll: false,
};
export default ReviewSummary;
